.order-block {
    display    : flex;
    align-items: center;
}

.order-block p,
.Interaction-block p {
    color      : #212529;
    font-size  : 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


.order-block .dropdown .dropdown-menu-right {
    height  : 250px;
    overflow: auto;
}

.order-block .outletDropdown {
    width        : 100%;
    text-align   : left;
    border       : 1px solid #ced4da;
    border-radius: .25rem;
    color        : #6c747c;

    &::after {
        position: absolute;
        right   : 10px;
        top     : 16px;
    }
}


input.form-control.order-inputbox {
    width         : calc(100% - 160px);
    // margin-left: 15px;
}

.Interaction-block {
    display    : flex;
    align-items: center;
}


.Interaction-block select {
    // max-width      : calc(100% - 280px);
    justify-content   : space-between;
    // margin-left    : 15px;
}

button.addsku-btn {
    background     : #efefef;
    border-radius  : 25px;
    font-size      : 14px;
    padding        : 0.45rem 1rem;
    margin-right   : 10px;
    display        : flex !important;
    justify-content: space-between;
    max-width      : 140px;
    width          : 125px;
}

.card.manual-order {
    background-color: #fff;
}

.card-header.manualhead-order {
    background: #fff;
}

button.addsku-btn img {
    width : 15px;
    height: 15px;
}

input.form-control.manual-srh {
    padding: 12px 52px 12px 23px !important;
}

.modal_new_seo.modal .modal-body {
    max-height: 400px;
    overflow  : auto;
}

input.form-control.sku-control {
    padding: 7px !important;
}

.addbtn-style {
    background  : url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAOlJREFUOE+V06E3REEYhvHfBgqBQlEkikJRKJIi+XMplC2ULRQChUKgO++euc6c6941O+XeMM8z3/fNOxPtawXHWMcDnoNO2nlneCpgRG/5bxUE+MasOnAuaRFk4wd2cIfPSnL+n+C3VGzhFFdFsovtRYIa3ijwFAel/wxzOiYYgm/KyXvYDDx2C4vgedkdPCRYCu4LloZrQQaT3h7xUgbW9fyn7Dp83RCTsusS1VxXE9xVsIZD3CJ5Pyqp++oPbCj2qSAl7tcPBJd4rac99mYiOMF9+Sbvq3jv5X70zUVwUbKeEwNG0rx+AEQWQrOd3PfxAAAAAElFTkSuQmCC) left center no-repeat;
    padding-left: 20px;
    height      : 16px;
    font-size   : 14px;
    display     : inline-flex;
    border      : none;
    outline     : none !important;

}

.main-content .card .card-body .rt-table .rt-tr:hover .rt-td .addbtn-style {
    color: #fff;
}

.main-content .card .card-body .rt-table .rt-tr:hover .rt-td button.addbtn-style {
    background: url(/static/media/pencil-hover.2bd3ea99.png) left center no-repeat;
}

.Interaction-txt {
    // max-width      : calc(100% - 280px);
    width          : 100%;
    justify-content: space-between;
    margin-left    : 16px;
    padding        : .5rem .75rem;
    height         : calc(1.72em + .75rem + 2px);
}
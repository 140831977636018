.modal_new_seo .modal-header button.close {
  color    : #adadad;
  font-size: 34px;
  padding  : 12px 12px;
}

.modal_new_seo .modal-header {
  border-bottom : 1px solid #ececec;
  font-size     : 20px;
  font-weight   : bold;
  color         : #7659ff;
  text-transform: capitalize;
}

.modal_new_seo .modal-dialog .modal-content {
  max-width: 600px;
  border   : 0;
}

.modal_new_seo .modal-dialog {
  margin         : 0;
  width          : 100%;
  max-width      : 100%;
  height         : 100%;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.frm_grp .react-datepicker-wrapper {
  width: 100%;
}

.frm_grp lable {
  font-size    : 14px;
  display      : block;
  color        : #212529;
  margin-bottom: 2px;
}

.frm_grp input {
  border       : 1px solid #ddd;
  border-radius: 4px;
  width        : 100%;
  height       : 36px;
  color        : #666;
  padding      : 4px 8px;
}

.frm_grp select {
  border       : 1px solid #ddd;
  border-radius: 4px;
  width        : 100%;
  height       : 36px;
  color        : #666;
  padding      : 4px 8px;
}

.react-datepicker-wrapper {
  display: block;
}

.frm_grp {
  flex         : 0 0 50%;
  max-width    : 50%;
  padding      : 0 15px;
  margin-bottom: 10px;
}

.frm_full {
  display  : flex;
  flex-wrap: wrap;
  margin   : 0 -15px;

  span {
    font-size: 11px;
    display  : block;
    width    : 100%;
    color    : #f00;
    margin   : 5px 0 0 0;
  }
}

.btn_pop {
  flex      : 0 0 100%;
  max-width : 100%;
  padding   : 0 15px;
  margin-top: 15px;
}

button.btn_grey:hover {
  background: transparent;
  color     : #666;
}

button.btn_grey {
  background   : #666666;
  color        : #fff;
  font-size    : 14px;
  padding      : 8px 32px;
  border-radius: 4px;
  border       : 1px solid #666666;
  transition   : ease-in-out .5s;
  line-height  : 20px;
}

.btn_white:hover {
  background: #666;
  color     : #fff;
}

.btn_white {
  background   : transparent;
  color        : #666;
  font-size    : 14px;
  padding      : 8px 32px;
  border-radius: 4px;
  border       : 1px solid #e6e6e6;
  transition   : ease-in-out .5s;
  line-height  : 20px;
  box-shadow   : 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

.btn_pop button {
  margin-right: 20px;
}

.frm_space {
  flex         : 0 0 50%;
  max-width    : 50%;
  padding      : 0 15px;
  margin-bottom: 10px;
}
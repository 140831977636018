.errorPage {
  background : #ebe7fc;
  display    : flex;
  align-items: center;
  padding    : 22px 40px;

  .imgWrap {
    margin-right : 20px;
    border-radius: 50%;
    padding      : 20px;
    background   : #7659ff;

    img {
      width: 75px;
    }
  }

  .error_text {
    p {
      font-size: 15px;
    }
  }
}